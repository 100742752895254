import type { TableHeaderDTO } from '@/dto/tableHeaderDTO';
import zipcelx from 'zipcelx';

interface ZipcelxCell {
    value: string;
    type: string;
}

type ZipcelxRow = Array<ZipcelxCell>;
type ZipcelxSheetData = Array<ZipcelxRow>;

/**
 * Download a blob as a file with the given filename.
 */
export const downloadFile = async (blob: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = decodeURI(url);
    a.download = decodeURIComponent(fileName);
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
}

const buildZipcelxCell = (dataRow: {[key: string]: unknown}, header: TableHeaderDTO): ZipcelxCell => {
    return {
        value: dataRow?.[header.fieldName]?.toString() ?? "",
        type: "string"
    }
}

const buildZipcelxRow = (dataRow: {[key: string]: unknown}, headers: Array<TableHeaderDTO>): ZipcelxRow => {
    return headers.map((header) => buildZipcelxCell(dataRow, header))
}


/**
 * Export table data to Excel format and trigger a download of that data
 * 
 * This function expexts data and columns in the same format used by the Table component.
 * Currently it just copies the specified field for each column
 * 
 * @param fileName: File name for the downloaded file
 * @param columns: Specification of which columns to include. Each column must include a header and a field name
 * @param data: Array of objects, where each object must contain the fields specified for the columns
 */
export const exportTable = async (fileName: string, columns: Array<TableHeaderDTO>, data: Array< {[key: string]: unknown}>) => {
    const headerRow: ZipcelxRow = columns.map((column) => ({
        value: column.header,
        type: "string"
    }))

    const sheetData: ZipcelxSheetData = [
        headerRow, 
        ...data.map(dataRow => buildZipcelxRow(dataRow, columns))
    ]
    const config = {
        filename: fileName,
        sheet: {
          data: sheetData
        }
      };
      
      zipcelx(config);
}

